import './App.css';

import AppImage from './AppImage';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';

function Support() {
  const navigate = useNavigate();

  const gotoHome = () => {
    navigate('/');
  };

  return (
    <div className="App">
      <Helmet>
        <title>My Nature Friends | Support</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <div className="App-content">
        <a href="/" onClick={gotoHome}>
          <img src="img/mnf-white.png" className="App-logo" alt="logo" />
        </a>
        <AppImage image={'img/1490601525.png'} name={'app.name'} />
      </div>
      <footer className="App-support">
        Please contact us at :{' '}
        <a href='mailto:"My Nature Friends Support"<support@mynaturefriends.me>'>
          support
          <span className="App-support-at">
            <img
              src="img/at_sign.png"
              style={{ width: 16, paddingLeft: 2, paddingRight: 2 }}
            />
          </span>
          mynaturefriends.me
        </a>
      </footer>
    </div>
  );
}

export default Support;
