function Rooter() {
  return (
    <footer className="App-footer">
      <a
        href="./app_privacy_policy.html"
        target="_blank"
        className="App-link"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
      <span> | </span>
      <a
        href="./tos.html"
        target="_blank"
        className="App-link"
        rel="noreferrer"
      >
        Terms of Service
      </a>
      <span> | </span>
      <a href="./support" className="App-link" rel="noreferrer">
        Support
      </a>
      <p>Copyright © 2024 My Nature Friends</p>
    </footer>
  );
}

export default Rooter;
