import './App.css';

import AppImage from './AppImage';
import Rooter from './Rooter';
import apps from './data';
import { useNavigate } from 'react-router-dom';

function Home() {
  const navigate = useNavigate();

  const gotoSupport = () => {
    navigate('/support');
  };

  return (
    <div className="App">
      <div className="App-content">
        <img src="img/mnf-white.png" className="App-logo" alt="logo" />
        {apps.map((app, index) => (
          <AppImage key={index} image={app.image} name={app.name} />
        ))}
      </div>
      <Rooter />
    </div>
  );
}

export default Home;
